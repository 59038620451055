export const useSliderTransition = (
  initialIndex?: number,
  useGap: boolean = true
) => {
  const selectedIndex = ref<number>(initialIndex ?? 0)

  const slider = ref<HTMLElement | null>(null)

  const getShortcutElement = (index: number): HTMLElement | null => {
    const elements = document.querySelectorAll<HTMLElement>('.shortcut-element')
    return elements[index] || null
  }

  const getShortcutWidth = (index: number): number => {
    const element = getShortcutElement(index)
    return element ? element.offsetWidth : 0
  }

  const getGap = (): number => {
    const sliderElement = document.querySelector<HTMLElement>('.slider')
    if (!sliderElement) return 8
    const styles = window.getComputedStyle(sliderElement)
    return parseFloat(styles.getPropertyValue('gap')) || 8
  }

  const updateSliderPosition = (index: number): void => {
    //await nextTick()

    if (!slider.value) return

    const shortcutWidth = getShortcutWidth(index)
    const gap = useGap ? getGap() : 0
    const translateX = calculateTranslateX(index, gap)

    slider.value.style.transform = `translateX(${translateX}px)`
    slider.value.style.width = `${shortcutWidth}px`
  }

  const calculateTranslateX = (index: number, gap: number): number => {
    return Array.from(document.querySelectorAll('.shortcut-element'))
      .slice(0, index)
      .reduce((acc, el) => acc + (el as HTMLElement).offsetWidth + gap, 0)
  }

  const handleShortcutClick = (index: number): void => {
    if (selectedIndex.value !== index) {
      selectedIndex.value = index
      updateSliderPosition(index)
    }
  }

  const handleKeydown = (event: KeyboardEvent): void => {
    const elements = document.querySelectorAll('.shortcut-element')

    const activeElement = document.activeElement as HTMLElement
    if (!activeElement.classList.contains('shortcut-element')) return

    if (event.key === 'ArrowRight') {
      event.preventDefault()
      selectedIndex.value = (selectedIndex.value + 1) % elements.length
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault()
      selectedIndex.value =
        selectedIndex.value > 0 ? selectedIndex.value - 1 : elements.length - 1
    }

    const newElement = getShortcutElement(selectedIndex.value)
    newElement?.focus()
    updateSliderPosition(selectedIndex.value)
  }

  const handleResize = () => updateSliderPosition(selectedIndex.value)

  onMounted(() => {
    updateSliderPosition(selectedIndex.value)
    window.addEventListener('resize', handleResize)
    window.addEventListener('keydown', handleKeydown)

    getShortcutElement(selectedIndex.value)?.focus()
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('keydown', handleKeydown)
  })

  watch(selectedIndex, (newIndex: number) => updateSliderPosition(newIndex))

  watch(slider, newV => {
    updateSliderPosition(selectedIndex.value)
  })

  return {
    selectedIndex,
    slider,
    handleShortcutClick,
    updateSliderPosition,
  }
}
